.furnishsection {
    padding: 50px 0;

    .furnishheading {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        h2 {
            font-size: 38px;
            line-height: 40px;
            color: #333333;
            font-family: "Abhay-Libre Bold";
            text-transform: capitalize;
            margin: 0px;
        }

        .headingNew {
            font-size: 38px;
            line-height: 40px;
            color: #333333;
            font-family: "Abhay-Libre Bold";
            text-transform: capitalize;
            margin: 0px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            font-family: "pangramregular";
            color: #515151;
            font-weight: normal;
            letter-spacing: 0.02em;
            margin: 0px;
        }

    }

    .furnishcard {
        display: block;
        text-align: center;
        background-color: #fff;
        box-shadow: 0px 2px 4px 0px #00000014;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 20px;

        figure {
            position: relative;
            overflow: hidden;
            border-radius: 4px;

            &:before {
                content: "";
                display: block;
                padding-top: 86%;
            }

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
                width: auto;
                height: auto;
                transform: scale(1);
                transition: 0.3s ease-in-out;
            }
        }

        &:hover {
            img {
                transform: scale(1.04);
            }

        }

        .furnishtext {
            padding: 11px 0px 6px;
            text-align: center;

            p {
                font-size: 13px;
                line-height: 20px;
                color: #515151;
                margin: 0px;
                font-family: "pangramregular";
            }

            .furnishname {
                font-family: "pangrammedium";
                font-size: 18px;
                line-height: 20px;
                color: #333333;
                letter-spacing: 0.04em;
                margin: 0px 0px 3px;
                display: block;
            }
        }
    }
}